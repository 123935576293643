import React, { useEffect, useState, useCallback } from 'react';
import api from '../api/axios';

interface User {
  id: number;
  username: string;
  email: string;
  status: string;
  createdAt: string;
  country: string;
  totalTrades: number;
  isVerified: boolean;
}

interface SortConfig {
  field: string;
  order: 'asc' | 'desc';
}

const Users: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [sort, setSort] = useState<SortConfig>({ field: 'id', order: 'asc' });
  const pageSize = 10;

  const fetchUsers = useCallback(async () => {
    try {
      setLoading(true);
      const { data, headers } = await api.get<User[]>('/api/users', {
        params: {
          page: currentPage,
          pageSize,
          search: searchTerm || undefined,
          sortBy: sort.field,
          sortOrder: sort.order
        }
      });
      setUsers(data);
      const totalCount = parseInt(headers['x-total-count'] || '0');
      setTotalPages(Math.ceil(totalCount / pageSize));
    } catch (error) {
      console.error('Error fetching users:', error);
    } finally {
      setLoading(false);
    }
  }, [currentPage, searchTerm, sort]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  const handleSort = (field: string) => {
    setSort(prev => ({
      field,
      order: prev.field === field && prev.order === 'asc' ? 'desc' : 'asc'
    }));
  };

  const getSortIcon = (field: string) => {
    if (sort.field !== field) return '↕️';
    return sort.order === 'asc' ? '↑' : '↓';
  };

  const renderPagination = () => {
    const pages = [];
    for (let i = 1; i <= totalPages; i++) {
      pages.push(
        <li key={i} className={`page-item ${currentPage === i ? 'active' : ''}`}>
          <button className="page-link" onClick={() => setCurrentPage(i)}>
            {i}
          </button>
        </li>
      );
    }
    return pages;
  };

  return (
    <div className="container-fluid">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h1>Users</h1>
        <div className="d-flex gap-2">
          <input
            type="text"
            className="form-control"
            placeholder="Search users..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <button className="btn btn-primary">Add User</button>
        </div>
      </div>
      
      <div className="card">
        <div className="card-body">
          {loading ? (
            <div className="text-center py-4">
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (
            <div className="table-responsive">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th onClick={() => handleSort('id')} style={{ cursor: 'pointer' }}>
                      ID {getSortIcon('id')}
                    </th>
                    <th onClick={() => handleSort('username')} style={{ cursor: 'pointer' }}>
                      Username {getSortIcon('username')}
                    </th>
                    <th onClick={() => handleSort('email')} style={{ cursor: 'pointer' }}>
                      Email {getSortIcon('email')}
                    </th>
                    <th onClick={() => handleSort('status')} style={{ cursor: 'pointer' }}>
                      Status {getSortIcon('status')}
                    </th>
                    <th onClick={() => handleSort('country')} style={{ cursor: 'pointer' }}>
                      Country {getSortIcon('country')}
                    </th>
                    <th onClick={() => handleSort('totaltrades')} style={{ cursor: 'pointer' }}>
                      Total Trades {getSortIcon('totaltrades')}
                    </th>
                    <th onClick={() => handleSort('createdat')} style={{ cursor: 'pointer' }}>
                      Created At {getSortIcon('createdat')}
                    </th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {users.length === 0 ? (
                    <tr>
                      <td colSpan={8} className="text-center">No users found</td>
                    </tr>
                  ) : (
                    users.map(user => (
                      <tr key={user.id}>
                        <td>{user.id}</td>
                        <td>
                          <div className="d-flex align-items-center">
                            {user.username}
                            {user.isVerified && (
                              <i className="bi bi-patch-check-fill text-primary ms-1" 
                                 title="Verified User"></i>
                            )}
                          </div>
                        </td>
                        <td>{user.email}</td>
                        <td>
                          <span className={`badge ${
                            user.status === 'Active' ? 'bg-success' :
                            user.status === 'Inactive' ? 'bg-warning' :
                            'bg-danger'
                          }`}>
                            {user.status}
                          </span>
                        </td>
                        <td>{user.country}</td>
                        <td>{user.totalTrades.toFixed(2)}</td>
                        <td>{new Date(user.createdAt).toLocaleDateString()}</td>
                        <td>
                          <div className="btn-group">
                            <button className="btn btn-sm btn-outline-primary">
                              <i className="bi bi-pencil"></i>
                            </button>
                            <button className="btn btn-sm btn-outline-danger">
                              <i className="bi bi-trash"></i>
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          )}
          
          <nav className="mt-3">
            <ul className="pagination justify-content-center">
              <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                <button 
                  className="page-link" 
                  onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                >
                  Previous
                </button>
              </li>
              {renderPagination()}
              <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                <button 
                  className="page-link" 
                  onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
                >
                  Next
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Users;
