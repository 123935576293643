import React, { useState, useEffect } from 'react';
import TickerWidget from '../components/TickerWidget';
import { useMarketData } from '../contexts/MarketDataContext';

const Markets: React.FC = () => {
    const [error, setError] = useState<string | null>(null);
    const [searchQuery, setSearchQuery] = useState<string>('');
    
    const symbols = ['BTCUSDT', 'ETHUSDT', 'TONUSDT', 'XRPUSDT', 'TRXUSDT', 'MNTUSDT'];
    const marketData = useMarketData();

    const filteredSymbols = symbols.filter(symbol => 
        symbol.toLowerCase().includes(searchQuery.toLowerCase())
    );

    useEffect(() => {
        const removeConnectionStatusListener = marketData.onConnectionStatus((error) => {
            setError(error ? 'Connection lost. Reconnecting...' : null);
        });

        return () => removeConnectionStatusListener();
    }, [marketData]);

    return (
        <div className="container-fluid">

            <div className="d-flex justify-content-between align-items-center mb-4">
                <h1>Markets</h1>
            </div>

            <div className="d-flex justify-content-between align-items-center mb-4">
                <input
                    type="text"
                    className="form-control w-50"
                    placeholder="Search markets..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                />
            </div>

            {error ? (
                        <div className="alert alert-danger" role="alert">
                            {error}
                        </div>
                    ) : (
                        <div className="d-flex flex-wrap">
                            {filteredSymbols.map(symbol => (
                                <TickerWidget key={symbol} symbol={symbol} />
                            ))}
                        </div>
                    )}
        </div>
    );
};

export default Markets;
