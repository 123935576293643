import { TickerData } from '../models/ticker';

export const getEmptyData = (symbol: string = ''): TickerData => ({
    symbol,
    lastPrice: 0,
    volume24h: 0,
    highPrice24h: 0,
    lowPrice24h: 0,
    previousPrice24h: 0,
    pricePercentage24h: 0,
    usdIndexPrice: 0
});
