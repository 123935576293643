import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Typography, Spin, Alert } from 'antd';
import { TickerData } from '../models/ticker';
import { useMarketData } from '../contexts/MarketDataContext';
import { useTheme } from '../contexts/ThemeContext';
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import { formatNumber } from '../utils/formatNumber';
import { getEmptyData } from '../utils/tickerUtils';
import TradingViewWidget from '../components/TradingViewWidget';

const { Text } = Typography;

const Ticker: React.FC = () => {
    const { symbol } = useParams<{ symbol: string }>();
    const location = useLocation();
    const { theme } = useTheme();
    const [tickerData, setTickerData] = useState<TickerData>(() => 
        location.state?.tickerData || getEmptyData(symbol ?? '')
    );
    const [error, setError] = useState<string | null>(null);
    const marketData = useMarketData();

    const handleTickerUpdate = useCallback((update: TickerData) => {
        setTickerData(update);
    }, []); // Empty dependency array since setTickerData is stable

    useEffect(() => {
        if (!symbol) return;

        console.info(`[+] Ticker subscribed to ${symbol} updates`);
        let cleanup: (() => void) | null = null;

        marketData.subscribeTicker(symbol, handleTickerUpdate)
            .then(() => {
                console.info(`[+] Ticker successfully subscribed to ${symbol} updates`);
                cleanup = () => {
                    console.info(`[-] Ticker unsubscribed from ${symbol} updates`);
                    marketData.unsubscribeTicker(symbol, handleTickerUpdate)
                        .catch(err => console.error(`Failed to unsubscribe from ${symbol}:`, err));
                };
            })
            .catch(err => console.error(`Failed to subscribe to ${symbol}:`, err));

        return () => cleanup?.();
    }, [symbol, marketData, handleTickerUpdate]);

    useEffect(() => {
        const removeConnectionStatusListener = marketData.onConnectionStatus((error) => {
            setError(error ? 'Connection lost. Reconnecting...' : null);
        });

        return () => removeConnectionStatusListener();
    }, [marketData]);

    if (!symbol) {
        return <Spin size="large" />;
    }

    const isPositive = (tickerData.pricePercentage24h || 0) >= 0;
    const Icon = isPositive ? ArrowUpOutlined : ArrowDownOutlined;

    return (
        <div style={{ padding: '24px' }}>
            {error && (
                <Alert
                    message={error}
                    type="error"
                    style={{ marginBottom: '16px' }}
                />
            )}
            <div className="card">
                <div className="card-body">
                    <h2 className="mb-3">{symbol}</h2>
                    {error ? (
                        <Alert message={error} type="error" />
                    ) : (
                        <div className="row g-4">
                            <div className="col-md-4">
                                <div>
                                    <Text type="secondary" className="text-body-secondary">Price:</Text>
                                    <div className="d-flex align-items-center gap-2">
                                        <h3 className="m-0 text-body">
                                            {formatNumber(tickerData.lastPrice, 5)}
                                        </h3>
                                        <Text
                                            className={`d-flex align-items-center ${tickerData.pricePercentage24h >= 0 ? 'text-success' : 'text-danger'}`}
                                        >
                                            <Icon /> {tickerData.pricePercentage24h.toFixed(5)}%
                                        </Text>
                                    </div>
                                </div>
                                <div className="mt-4">
                                    <div className="d-flex align-items-center gap-3">
                                        <div>
                                            <Text type="secondary" className="text-body-secondary small">High</Text>
                                            <h5 className="m-0 text-body">
                                                {formatNumber(tickerData.highPrice24h, 5)}
                                            </h5>
                                        </div>
                                        <div>
                                            <Text type="secondary" className="text-body-secondary small">Low</Text>
                                            <h5 className="m-0 text-body">
                                                {formatNumber(tickerData.lowPrice24h, 5)}
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div>
                                    <Text type="secondary" className="text-body-secondary">24h Volume:</Text>
                                    <div>
                                        <h4 className="m-0 text-body">
                                            {formatNumber(tickerData.volume24h, 2)}
                                        </h4>
                                    </div>
                                </div>
                                <div className="mt-4">
                                    <Text type="secondary" className="text-body-secondary">Previous Price (24h):</Text>
                                    <div>
                                        <h5 className="m-0 text-body">
                                            {formatNumber(tickerData.previousPrice24h, 5)}
                                        </h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div>
                                    <Text type="secondary" className="text-body-secondary">USD Index Price:</Text>
                                    <div>
                                        <h5 className="m-0 text-body">
                                            {formatNumber(tickerData.usdIndexPrice, 5)}
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div style={{ marginTop: '16px' }}>
                <TradingViewWidget symbol={symbol ?? ''} theme={theme === 'dark' ? 'dark' : 'light'} />
            </div>
        </div>
    );
};

export default Ticker;
