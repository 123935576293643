import React, { createContext, useContext, useEffect, useRef } from 'react';
import * as signalR from '@microsoft/signalr';
import { TickerData } from '../models/ticker';

type TickerUpdateCallback = (update: TickerData) => void;
type ConnectionStatusCallback = (error: Error | null) => void;

interface MarketDataContextType {
    subscribeTicker: (symbol: string, callback: TickerUpdateCallback) => Promise<void>;
    unsubscribeTicker: (symbol: string, callback: TickerUpdateCallback) => Promise<void>;
    onConnectionStatus: (callback: ConnectionStatusCallback) => () => void;
    isConnected: boolean;
}

const MarketDataContext = createContext<MarketDataContextType | null>(null);

export const useMarketData = () => {
    const context = useContext(MarketDataContext);
    if (!context) {
        throw new Error('useMarketData must be used within a MarketDataProvider');
    }
    return context;
};

interface MarketDataProviderProps {
    children: React.ReactNode;
}

export const MarketDataProvider: React.FC<MarketDataProviderProps> = ({ children }) => {
    const connectionRef = useRef<signalR.HubConnection | null>(null);
    const subscribersRef = useRef(new Map<string, Set<TickerUpdateCallback>>());
    const connectionStatusCallbacksRef = useRef(new Set<ConnectionStatusCallback>());
    const connectingRef = useRef<Promise<void> | null>(null);
    const subscriptionQueueRef = useRef<Array<() => Promise<void>>>([]);
    const isProcessingQueueRef = useRef(false);

    const handleTickerUpdate = async (update: TickerData): Promise<void> => {
        const symbol = update.symbol;
        const callbacks = subscribersRef.current.get(symbol);
        if (callbacks) {
            callbacks.forEach(callback => {
                try {
                    callback(update);
                } catch (error) {
                    console.error(`Error in ticker callback for ${symbol}:`, error);
                }
            });
        }
    };

    const processSubscriptionQueue = async (): Promise<void> => {
        if (isProcessingQueueRef.current) return;
        isProcessingQueueRef.current = true;

        try {
            while (subscriptionQueueRef.current.length > 0) {
                const task = subscriptionQueueRef.current.shift();
                if (task) {
                    await task();
                }
            }
        } finally {
            isProcessingQueueRef.current = false;
        }
    };

    const notifyConnectionStatus = (error: Error | null): void => {
        connectionStatusCallbacksRef.current.forEach(callback => callback(error));
    };

    const startConnection = async (): Promise<void> => {
        if (connectionRef.current) {
            console.warn('[-] Stopping existing connection...');
            await connectionRef.current.stop();
            connectionRef.current = null;
        }

        console.log('Environment variables:', {
            REACT_APP_API_URL: process.env.REACT_APP_API_URL,
            NODE_ENV: process.env.NODE_ENV,
            all: process.env
        });

        const apiUrl = process.env.REACT_APP_API_URL ?? 'http://localhost:5050';
        console.log('Using API URL:', apiUrl);

        const newConnection = new signalR.HubConnectionBuilder()
            .withUrl(`${apiUrl}/hubs/market`, {
                skipNegotiation: true,
                transport: signalR.HttpTransportType.WebSockets
            })
            .configureLogging(signalR.LogLevel.Debug)
            .withAutomaticReconnect()
            .build();

        newConnection.on('TickerUpdate', handleTickerUpdate);

        newConnection.onreconnecting((error) => {
            notifyConnectionStatus(error || null);
        });

        newConnection.onreconnected(() => {
            notifyConnectionStatus(null);
            resubscribeAll();
        });

        newConnection.onclose((error) => {
            notifyConnectionStatus(error || new Error('Connection closed'));
        });

        try {
            console.warn('[+] Starting connection...');
            await newConnection.start();
            connectionRef.current = newConnection;
        } catch (error) {
            notifyConnectionStatus(error as Error);
            throw error;
        }
    };

    const ensureConnection = async (): Promise<void> => {
        if (connectionRef.current?.state === signalR.HubConnectionState.Connected) return;
        
        if (connectingRef.current) {
            try {
                await connectingRef.current;
                return;
            } catch (error) {
                connectingRef.current = null;
            }
        }

        try {
            connectingRef.current = startConnection();
            await connectingRef.current;
        } finally {
            connectingRef.current = null;
        }
        
        await processSubscriptionQueue();
    };

    const resubscribeAll = async (): Promise<void> => {
        if (!connectionRef.current) return;
        
        const symbols = Array.from(subscribersRef.current.keys());
        for (const symbol of symbols) {
            if (subscribersRef.current.get(symbol)?.size) {
                await connectionRef.current.invoke('SubscribeToTicker', symbol);
            }
        }
    };

    const subscribeTicker = async (symbol: string, callback: TickerUpdateCallback): Promise<void> => {
        let callbacks = subscribersRef.current.get(symbol);
        if (!callbacks) {
            callbacks = new Set();
            subscribersRef.current.set(symbol, callbacks);
        }
        callbacks.add(callback);
        console.info(`[+] Added callback for ${symbol}, total callbacks: ${callbacks.size}`);

       
        try {
            if (callbacks.size === 1) {
                await ensureConnection();
                await connectionRef.current?.invoke('SubscribeToTicker', symbol);
                console.info(`[+] Subscribed to ${symbol} updates on server`);
            }
        } catch (error) {
            callbacks.delete(callback);
            if (callbacks.size === 0) {
                subscribersRef.current.delete(symbol);
                console.info(`[-] Removed ${symbol} from subscribers due to error`);
            }
            throw error;
        }
    };

    const unsubscribeTicker = async (symbol: string, callback: TickerUpdateCallback): Promise<void> => {
        const callbacks = subscribersRef.current.get(symbol);
        if (!callbacks) return;

        callbacks.delete(callback);
        console.info(`[-] Removed callback for ${symbol}, remaining callbacks: ${callbacks.size}`);

        if (callbacks.size === 0) {
            subscribersRef.current.delete(symbol);
            console.info(`[-] Removed ${symbol} from subscribers, total symbols: ${subscribersRef.current.size}`);

            try {
                if (connectionRef.current?.state === signalR.HubConnectionState.Connected) {
                    await connectionRef.current.invoke('UnsubscribeTicker', symbol);
                    console.info(`[-] Unsubscribed from ${symbol} updates on server`);
                }
            } catch (error) {
                console.warn(`Failed to unsubscribe from ${symbol}:`, error);
                // Восстанавливаем подписку в случае ошибки
                if (callback) {
                    const newCallbacks = new Set([callback]);
                    subscribersRef.current.set(symbol, newCallbacks);
                    console.info(`[+] Restored subscription for ${symbol} due to unsubscribe error`);
                }
            }
        }
    };

    const onConnectionStatus = (callback: ConnectionStatusCallback): (() => void) => {
        connectionStatusCallbacksRef.current.add(callback);
        return () => connectionStatusCallbacksRef.current.delete(callback);
    };

    useEffect(() => {
        return () => {
            if (connectionRef.current) {
                connectionRef.current.stop().catch(console.error);
            }
        };
    }, []);

    const value = {
        subscribeTicker,
        unsubscribeTicker,
        onConnectionStatus,
        isConnected: connectionRef.current?.state === signalR.HubConnectionState.Connected,
    };

    return (
        <MarketDataContext.Provider value={value}>
            {children}
        </MarketDataContext.Provider>
    );
};
