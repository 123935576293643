import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import MainLayout from './layouts/MainLayout';
import Dashboard from './pages/Dashboard';
import Users from './pages/Users';
import Settings from './pages/Settings';
import Markets from './pages/Markets';
import Ticker from './pages/Ticker';
import { ThemeProvider } from './contexts/ThemeContext';
import { MarketDataProvider } from './contexts/MarketDataContext';
// Import custom Bootstrap with overrides
import './styles/custom.scss';
// Import Bootstrap JS and Popper.js
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'antd/dist/reset.css';

function App() {
  return (
    <ThemeProvider>
      <MarketDataProvider>
        <Router>
          <Routes>
            <Route path="/" element={<MainLayout />}>
              <Route index element={<Dashboard />} />
              <Route path="markets" element={<Markets />} />
              <Route path="ticker/:symbol" element={<Ticker />} />
              <Route path="users" element={<Users />} />
              <Route path="settings" element={<Settings />} />
              <Route path="*" element={<Navigate to="/" replace />} />
            </Route>
          </Routes>
        </Router>
      </MarketDataProvider>
    </ThemeProvider>
  );
}

export default App;
