import React, { useState } from 'react';
import { Outlet, Link, useLocation } from 'react-router-dom';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { useTheme } from '../contexts/ThemeContext';

const MainLayout: React.FC = () => {
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
  const location = useLocation();
  const { theme, toggleTheme } = useTheme();

  const isActivePath = (path: string) => {
    return location.pathname === path;
  };

  return (
    <div className="d-flex">
      {/* SVG definitions */}
      <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'none' }}>
        <symbol id="check2" viewBox="0 0 16 16">
          <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
        </symbol>
        <symbol id="circle-half" viewBox="0 0 16 16">
          <path d="M8 15A7 7 0 1 0 8 1v14zm0 1A8 8 0 1 1 8 0a8 8 0 0 1 0 16z"/>
        </symbol>
        <symbol id="sun-fill" viewBox="0 0 16 16">
          <path d="M8 12a4 4 0 1 0 0-8 4 4 0 0 0 0 8zM8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0zm0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13zm8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zM3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zM3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8zm10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0zm-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707zM4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708z"/>
        </symbol>
        <symbol id="moon-stars-fill" viewBox="0 0 16 16">
          <path d="M6 .278a.768.768 0 0 1 .08.858 7.208 7.208 0 0 0-.878 3.46c0 4.021 3.278 7.277 7.318 7.277.527 0 1.04-.055 1.533-.16a.787.787 0 0 1 .81.316.733.733 0 0 1-.031.893A8.349 8.349 0 0 1 8.344 16C3.734 16 0 12.286 0 7.71 0 4.266 2.114 1.312 5.124.06A.752.752 0 0 1 6 .278z"/>
          <path d="M10.794 3.148a.217.217 0 0 1 .412 0l.387 1.162c.173.518.579.924 1.097 1.097l1.162.387a.217.217 0 0 1 0 .412l-1.162.387a1.734 1.734 0 0 0-1.097 1.097l-.387 1.162a.217.217 0 0 1-.412 0l-.387-1.162A1.734 1.734 0 0 0 9.31 6.593l-1.162-.387a.217.217 0 0 1 0-.412l1.162-.387a1.734 1.734 0 0 0 1.097-1.097l.387-1.162zM13.863.099a.145.145 0 0 1 .274 0l.258.774c.115.346.386.617.732.732l.774.258a.145.145 0 0 1 0 .274l-.774.258a1.156 1.156 0 0 0-.732.732l-.258.774a.145.145 0 0 1-.274 0l-.258-.774a1.156 1.156 0 0 0-.732-.732l-.774-.258a.145.145 0 0 1 0-.274l.774-.258c.346-.115.617-.386.732-.732L13.863.1z"/>
        </symbol>
      </svg>

      {/* Sidebar */}
      <div className={`d-flex flex-column flex-shrink-0 p-3 text-bg-dark ${sidebarCollapsed ? 'sidebar-collapsed' : ''}`} 
           style={{ width: sidebarCollapsed ? '4.5rem' : '200px', minHeight: '100vh', transition: 'width 0.3s' }}>
        <div className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none">
          {!sidebarCollapsed && <span className="fs-4">Admin</span>}
          <button 
            className={`btn btn-link text-white ms-auto p-0 ${sidebarCollapsed ? 'w-100 text-center' : ''}`} 
            onClick={() => setSidebarCollapsed(!sidebarCollapsed)}
          >
            <i className={`bi bi-${sidebarCollapsed ? 'chevron-right' : 'chevron-left'}`}></i>
          </button>
        </div>
        <hr />
        <ul className="nav nav-pills flex-column mb-auto">
          <li className="nav-item">
            <Link 
              to="/" 
              className={`nav-link text-white ${isActivePath('/') ? 'active' : ''}`}
              title="Dashboard"
            >
              <i className="bi bi-speedometer2 me-2"></i>
              {!sidebarCollapsed && <span>Dashboard</span>}
            </Link>
          </li>
          <li className="nav-item">
            <Link 
              to="/markets" 
              className={`nav-link text-white ${isActivePath('/markets') ? 'active' : ''}`}
              title="Markets"
            >
              <i className="bi bi-graph-up me-2"></i>
              {!sidebarCollapsed && <span>Markets</span>}
            </Link>
          </li>
          <li className="nav-item">
            <Link 
              to="/users" 
              className={`nav-link text-white ${isActivePath('/users') ? 'active' : ''}`}
              title="Users"
            >
              <i className="bi bi-people me-2"></i>
              {!sidebarCollapsed && <span>Users</span>}
            </Link>
          </li>
          <li className="nav-item">
            <Link 
              to="/settings" 
              className={`nav-link text-white ${isActivePath('/settings') ? 'active' : ''}`}
              title="Settings"
            >
              <i className="bi bi-gear me-2"></i>
              {!sidebarCollapsed && <span>Settings</span>}
            </Link>
          </li>
        </ul>
        <hr />
        <div className={`dropdown ${sidebarCollapsed ? 'dropend' : ''}`}>
          <a href="#" className="d-flex align-items-center text-white text-decoration-none dropdown-toggle" 
             data-bs-toggle="dropdown" aria-expanded="false">
            <img src="https://github.com/mdo.png" alt="" width="32" height="32" className="rounded-circle me-2" />
            {!sidebarCollapsed && <strong>Admin</strong>}
          </a>
          <ul className="dropdown-menu dropdown-menu-dark text-small shadow">
            <li><a className="dropdown-item" href="#"><i className="bi bi-person me-2"></i>Profile</a></li>
            <li><hr className="dropdown-divider" /></li>
            <li><a className="dropdown-item" href="#"><i className="bi bi-box-arrow-right me-2"></i>Sign out</a></li>
          </ul>
        </div>
      </div>

      {/* Theme toggle */}
      <div className="dropdown position-fixed bottom-0 end-0 mb-3 me-3 bd-mode-toggle">
        <button 
          className="btn btn-bd-primary py-2 dropdown-toggle d-flex align-items-center"
          id="bd-theme"
          type="button"
          aria-expanded="false"
          data-bs-toggle="dropdown"
          aria-label={`Toggle theme (${theme})`}
        >
          <svg className="bi my-1 theme-icon-active" width="1em" height="1em">
            <use href={`#${theme === 'dark' ? 'moon-stars-fill' : 'sun-fill'}`}></use>
          </svg>
          <span className="visually-hidden" id="bd-theme-text">Toggle theme</span>
        </button>
        <ul className="dropdown-menu dropdown-menu-end shadow" aria-labelledby="bd-theme-text">
          <li>
            <button 
              type="button" 
              className={`dropdown-item d-flex align-items-center ${theme === 'light' ? 'active' : ''}`}
              onClick={() => toggleTheme()}
              data-bs-theme-value="light"
              aria-pressed={theme === 'light'}
            >
              <svg className="bi me-2 opacity-50" width="1em" height="1em"><use href="#sun-fill"></use></svg>
              Light
              <svg className={`bi ms-auto ${theme === 'light' ? '' : 'd-none'}`} width="1em" height="1em">
                <use href="#check2"></use>
              </svg>
            </button>
          </li>
          <li>
            <button 
              type="button" 
              className={`dropdown-item d-flex align-items-center ${theme === 'dark' ? 'active' : ''}`}
              onClick={() => toggleTheme()}
              data-bs-theme-value="dark"
              aria-pressed={theme === 'dark'}
            >
              <svg className="bi me-2 opacity-50" width="1em" height="1em"><use href="#moon-stars-fill"></use></svg>
              Dark
              <svg className={`bi ms-auto ${theme === 'dark' ? '' : 'd-none'}`} width="1em" height="1em">
                <use href="#check2"></use>
              </svg>
            </button>
          </li>
        </ul>
      </div>

      {/* Main content */}
      <div className="flex-grow-1 p-3 overflow-auto" style={{ maxHeight: '100vh' }}>
        <Outlet />
      </div>
    </div>
  );
};

export default MainLayout;
