import React, { useEffect, useRef } from 'react';

declare global {
    interface Window {
        TradingView: any;
    }
}

interface TradingViewWidgetProps {
    symbol: string;
    theme?: 'light' | 'dark';
}

const TradingViewWidget: React.FC<TradingViewWidgetProps> = ({ symbol, theme = 'light' }) => {
    const container = useRef<HTMLDivElement>(null);

    useEffect(() => {
        // Remove any existing script to prevent duplicates
        const existingScript = document.getElementById('tradingview-widget-script');
        if (existingScript) {
            existingScript.remove();
        }

        // Create and load the TradingView script
        const script = document.createElement('script');
        script.id = 'tradingview-widget-script';
        script.src = 'https://s3.tradingview.com/tv.js';
        script.async = true;
        script.onload = () => {
            if (container.current) {
                new window.TradingView.widget({
                    container_id: container.current.id,
                    symbol: `BINANCE:${symbol}`,
                    interval: 'D',
                    timezone: 'exchange',
                    theme: theme,
                    style: '1',
                    locale: 'en',
                    toolbar_bg: '#f1f3f6',
                    enable_publishing: false,
                    allow_symbol_change: true,
                    save_image: false,
                    height: 500,
                    width: '100%',
                });
            }
        };
        document.head.appendChild(script);

        return () => {
            if (script) {
                script.remove();
            }
        };
    }, [symbol, theme]);

    return <div id={`tradingview-widget-${symbol}`} ref={container} />;
};

export default TradingViewWidget;
