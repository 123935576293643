import React from 'react';

const Settings: React.FC = () => {
  return (
    <div className="container">
      <h1 className="mb-4">Settings</h1>
      
      <div className="card mb-4">
        <div className="card-header">
          <h5 className="card-title mb-0">General Settings</h5>
        </div>
        <div className="card-body">
          <form>
            <div className="mb-3">
              <label htmlFor="siteName" className="form-label">Site Name</label>
              <input type="text" className="form-control" id="siteName" placeholder="CryptoTrading" />
            </div>
            <div className="mb-3">
              <label htmlFor="adminEmail" className="form-label">Admin Email</label>
              <input type="email" className="form-control" id="adminEmail" placeholder="admin@example.com" />
            </div>
            <div className="mb-3">
              <label htmlFor="timeZone" className="form-label">Time Zone</label>
              <select className="form-select" id="timeZone">
                <option value="UTC">UTC</option>
                <option value="GMT">GMT</option>
                <option value="EST">EST</option>
                <option value="PST">PST</option>
              </select>
            </div>
            <button type="submit" className="btn btn-primary">Save Changes</button>
          </form>
        </div>
      </div>

      <div className="card">
        <div className="card-header">
          <h5 className="card-title mb-0">Security Settings</h5>
        </div>
        <div className="card-body">
          <form>
            <div className="mb-3">
              <div className="form-check form-switch">
                <input className="form-check-input" type="checkbox" id="twoFactorAuth" />
                <label className="form-check-label" htmlFor="twoFactorAuth">Enable Two-Factor Authentication</label>
              </div>
            </div>
            <div className="mb-3">
              <div className="form-check form-switch">
                <input className="form-check-input" type="checkbox" id="loginNotifications" />
                <label className="form-check-label" htmlFor="loginNotifications">Email Notifications on Login</label>
              </div>
            </div>
            <button type="submit" className="btn btn-primary">Save Changes</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Settings;
