import React, { useEffect, useState, useCallback } from 'react';
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import { TickerData } from '../models/ticker';
import { useMarketData } from '../contexts/MarketDataContext';
import { useNavigate } from 'react-router-dom';
import { formatNumber } from '../utils/formatNumber';
import { getEmptyData } from '../utils/tickerUtils';

const TickerWidget: React.FC<{ symbol: string }> = ({ symbol }) => {
    const [tickerData, setTickerData] = useState<TickerData>(getEmptyData(symbol));
    const marketData = useMarketData();
    const navigate = useNavigate();

    const handleTickerUpdate = useCallback((update: TickerData) => {
        setTickerData(update);
    }, []);

    useEffect(() => {
        console.info(`[+] TickerWidget subscribed to ${symbol} updates`);
        let cleanup: (() => void) | null = null;

        marketData.subscribeTicker(symbol, handleTickerUpdate)
            .then(() => {
                console.info(`[+] TickerWidget successfully subscribed to ${symbol} updates`);
                cleanup = () => {
                    console.info(`[-] TickerWidget unsubscribed from ${symbol} updates`);
                    marketData.unsubscribeTicker(symbol, handleTickerUpdate)
                        .catch(err => console.error(`Failed to unsubscribe from ${symbol}:`, err));
                };
            })
            .catch(err => console.error(`Failed to subscribe to ${symbol}:`, err));

        return () => cleanup?.();
    }, [symbol, marketData, handleTickerUpdate]);

    const isPositive = (tickerData.pricePercentage24h || 0) >= 0;
    const Icon = isPositive ? ArrowUpOutlined : ArrowDownOutlined;

    const handleClick = () => {
        navigate(`/ticker/${symbol}`, { state: { tickerData } });
    };

    return (
        <div 
            className='card'
            onClick={handleClick}
            style={{ width: 270, margin: 8, cursor: 'pointer' }}
        >
            <div className="card-header">{symbol}</div>
            <div className="card-body d-flex flex-column gap-2">
                <div className="d-flex justify-content-between align-items-center">
                    <span className="fw-bold text-body fs-5">
                        ${formatNumber(tickerData.lastPrice, 5)}
                    </span>
                    <span className={tickerData.pricePercentage24h >= 0 ? 'text-success' : 'text-danger'}>
                        <Icon /> {formatNumber(tickerData.pricePercentage24h)}%
                    </span>
                </div>
                <div className="d-flex justify-content-between">
                    <span className="text-body-secondary">24h High</span>
                    <span className="text-body">${formatNumber(tickerData.highPrice24h, 4)}</span>
                </div>
                <div className="d-flex justify-content-between">
                    <span className="text-body-secondary">24h Low</span>
                    <span className="text-body">${formatNumber(tickerData.lowPrice24h, 4)}</span>
                </div>
                <div className="d-flex justify-content-between">
                    <span className="text-body-secondary">24h Volume</span>
                    <span className="text-body">{formatNumber(tickerData.volume24h)}</span>
                </div>
                <div className="d-flex justify-content-between">
                    <span className="text-body-secondary">Index Price</span>
                    <span className="text-body">${formatNumber(tickerData.usdIndexPrice, 4)}</span>
                </div>
            </div>
        </div>
    );
};

export default TickerWidget;
